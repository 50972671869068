:root {
  --pannor-color: #fa7268;
  --main-color: #222;
  --secondary-color: #888;
  --background-color: #f5f5f5;
  --pannor-medium: 'Pannor Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --pannor-regular: 'Pannor Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

*, *::before, *::after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

@font-face {
  font-family: 'Pannor Regular';
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/pannor/Pannor-Regular.eot?r-1810058");
  src: url("../src/fonts/pannor/Pannor-Regular.eot?#iefix?r-1810058") format("embedded-opentype"), 
       url("../src/fonts/pannor/Pannor-Regular.svg?v=20151022b#Pannor-Regular?r-1810058") format("svgz"),
       url("../src/fonts/pannor/Pannor-Regular.svg?v=20151022b#Pannor-Regular?r-1810058") format("svg"),
       url("../src/fonts/pannor/Pannor-Regular.woff?v=20151022b?r-1810058") format("woff"),
       url("../src/fonts/pannor/Pannor-Regular.woff?v=20151022b?r-1810058") format("woff")
}

@font-face {
  font-family: 'Pannor Medium';
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/pannor/Pannor-Medium.eot?r-1810058");
  src: url("../src/fonts/pannor/Pannor-Medium.eot?#iefix?r-1810058") format("embedded-opentype"), 
       url("../src/fonts/pannor/Pannor-Medium.svg?v=20151022b#Pannor-Medium?r-1810058") format("svgz"),
       url("../src/fonts/pannor/Pannor-Medium.svg?v=20151022b#Pannor-Medium?r-1810058") format("svg"),
       url("../src/fonts/pannor/Pannor-Medium.woff?v=20151022b?r-1810058") format("woff"),
       url("../src/fonts/pannor/Pannor-Medium.woff?v=20151022b?r-1810058") format("woff")
}

@font-face {
  font-family: 'Pannor Bold';
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/pannor/Pannor-Bold.eot?r-1810058");
  src: url("../src/fonts/pannor/Pannor-Bold.eot?#iefix?r-1810058") format("embedded-opentype"),
       url("../src/fonts/pannor/Pannor-Bold.svg?v=20151022b#Pannor-bold?r-1810058") format("svgz"),
       url("../src/fonts/pannor/Pannor-Bold.svg?v=20151022b#Pannor-bold?r-1810058") format("svg"),
       url("../src/fonts/pannor/Pannor-Bold.woff?v=20151022b?r-1810058") format("woff"),
       url("../src/fonts/pannor/Pannor-Bold.woff?v=20151022b?r-1810058") format("woff")
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-Thin.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-Thin.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-ThinItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-ThinItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-ExtraLight.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-ExtraLight.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-ExtraLightItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-ExtraLightItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-Light.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-Light.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-LightItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-LightItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-Regular.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-Regular.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-Italic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-Italic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-Medium.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-Medium.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-MediumItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-MediumItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-SemiBold.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-SemiBold.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-SemiBoldItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-SemiBoldItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-Bold.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-Bold.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-BoldItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-BoldItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-ExtraBold.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-ExtraBold.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-ExtraBoldItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-ExtraBoldItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-Black.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-Black.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  font-display: swap;
  src: url("../src/fonts/inter/Inter-BlackItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/inter/Inter-BlackItalic.woff?v=3.13") format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
